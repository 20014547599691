<template>
  <div>
    <div class="z-0">
      <header>
        <ConnectWallet />
      </header>

      <div v-if="!getMenu">
        <div class="mx-auto sm:max-w-xl md:max-w-full xl:max-w-screen-2xl p-2">
          <!-- <div class="">
          <video playsinline autoplay muted loop>
            <source
              src="https://cdn-stamplib.casetify.com/cms/video/36370d5385f1ecf16c9f7fa9f7008cb7.mp4"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </div> -->

          <Carousel :autoplay="2000" :wrap-around="true">
            <Slide v-for="slide in slideList" :key="slide">
              <img :src="slide.img" />
            </Slide>

            <template #addons>
              <Navigation />
              <Pagination />
            </template>
          </Carousel>

          <!-- <img
          class="h-96 lg:h-full object-cover"
          src="https://cdn.discordapp.com/attachments/1080058777971212370/1126410892519682129/POD_Banner_for_web_02.png"
        /> -->

          <div class="mt-10">
            <div class="grid grid-cols-2 lg:grid-cols-4 md:grid-cols-4">
              <div>
                <hr />

                <img
                  class="h-full"
                  src="https://cdn-stamplib.casetify.com/cms/image/55543e4d893322bf7ee2c6ec61f4c3b8.jpg.webp"
                />

                <hr />
              </div>

              <div>
                <hr />

                <img
                  class="h-full"
                  src="https://cdn-stamplib.casetify.com/cms/image/bab332ed2ff04f590942261c943ee3a1.png.webp"
                />

                <hr />
              </div>
              <div>
                <hr />

                <img
                  class="h-full"
                  src="https://cdn-stamplib.casetify.com/cms/image/b163fa92e335e9dcb6d0419e3e434996.png.webp"
                />

                <hr />
              </div>
              <div>
                <hr />

                <img
                  class="h-full"
                  src="https://cdn-stamplib.casetify.com/cms/image/8c11b59edfe37e71403246bbebdd3164.png.webp"
                />

                <hr />
              </div>
            </div>
          </div>

          <div class="mt-44">
            <h3 class="text-3xl font-sans font-bold">Featured Collection</h3>
            <div
              class="grid gap-4 grid-cols-1 lg:grid-cols-3 md:grid-cols-2 mt-10"
            >
              <div>
                <img
                  class="w-full"
                  src="https://cdn.discordapp.com/attachments/1080058777971212370/1126469662708858932/POD_Banner_Featured_Collection_01.png"
                />
                <h3 class="text-xl font-bold pt-5">Stainless Steel Band:</h3>
                <h3 class="text-xl font-bold pt-1">
                  Designed with Style, Crafted to Last
                </h3>

                <div
                  class="inline-block mt-5 p-px bg-gradient-to-r from-blue-700 to-red-500 rounded-2xl"
                >
                  <div
                    class="inline-block text-base px-2 py-1 sm:px-4 sm:py-2 border-none rounded-2xl text-gray-200 bg-gray-900 cursor-pointer hover:bg-gray-200 hover:text-gray-900"
                  >
                    Learn More
                  </div>
                </div>
              </div>

              <div>
                <img
                  class="w-full"
                  src="https://cdn.discordapp.com/attachments/1080058777971212370/1126469684376637580/POD_Banner_Featured_Collection_02.png"
                />
                <h3 class="text-xl font-bold pt-5">
                  Best Choice for Everyday Protection
                </h3>

                <div
                  class="inline-block mt-16 p-px bg-gradient-to-r from-blue-700 to-red-500 rounded-2xl"
                >
                  <div
                    class="inline-block text-base px-2 py-1 sm:px-4 sm:py-2 border-none rounded-2xl text-gray-200 bg-gray-900 cursor-pointer hover:bg-gray-200 hover:text-gray-900"
                  >
                    Learn More
                  </div>
                </div>
              </div>

              <div>
                <img
                  class="w-full"
                  src="https://cdn.discordapp.com/attachments/1080058777971212370/1126469711685767239/POD_Banner_Featured_Collection_03.png"
                />
                <h3 class="text-xl font-bold pt-5">
                  Phone Straps for Your Style:
                </h3>
                <h3 class="text-xl font-bold pt-1">Phone Close, Hands Free</h3>

                <div
                  class="inline-block mt-5 p-px bg-gradient-to-r from-blue-700 to-red-500 rounded-2xl"
                >
                  <div
                    class="inline-block text-base px-2 py-1 sm:px-4 sm:py-2 border-none rounded-2xl text-gray-200 bg-gray-900 cursor-pointer hover:bg-gray-200 hover:text-gray-900"
                  >
                    Learn More
                  </div>
                </div>
              </div>
            </div>
            <hr class="mt-10 mb-10" />
          </div>

          <div class="mt-44">
            <h3 class="text-3xl font-sans font-bold">Collectibles</h3>
            <div
              class="grid gap-4 grid-cols-1 lg:grid-cols-4 md:grid-cols-2 mt-10"
            >
              <div>
                <img
                  class="w-full"
                  src="https://cdn-stamplib.casetify.com/cms/image/f9cda8ccbe5dc0f75b7fd0737866b68b.png"
                />
                <div class="text-center">
                  <h3 class="text-xl pt-5">
                    Sorayama Stainless Steel <br />
                    Phone Case
                  </h3>
                </div>
              </div>

              <div>
                <img
                  class="w-full"
                  src="https://cdn-stamplib.casetify.com/cms/image/cab2dc6de69b9df6b9b560a183f95c38.png"
                />
                <div class="text-center">
                  <h3 class="text-xl pt-5">BE@RBRICK Set Box</h3>
                </div>
              </div>

              <div>
                <img
                  class="w-full"
                  src="https://cdn-stamplib.casetify.com/cms/image/d40a57b9f640b2db9a119c066ab2c5c0.png"
                />
                <div class="text-center">
                  <h3 class="text-xl pt-5">
                    Golden Snitch Collectible <br />
                    AirPods Case
                  </h3>
                </div>
              </div>

              <div>
                <img
                  class="w-full"
                  src="https://cdn-stamplib.casetify.com/cms/image/54b67517cfd5b3d9e78af4994edce992.jpg"
                />
                <div class="text-center">
                  <h3 class="text-xl pt-5">
                    Crystal Star Compact Collectible <br />
                    AirPods Case
                  </h3>
                </div>
              </div>

              <div>
                <img
                  class="h-96"
                  src="https://cdn.discordapp.com/attachments/1080058777971212370/1130745088365039627/black-t-shirt-isolated-2022-11-10-01-08-03-utc.jpg"
                />
                <div class="text-center">
                  <h3 class="text-xl pt-5">
                    Crystal Star Compact Collectible <br />
                    AirPods Case
                  </h3>
                </div>
              </div>

              <div>
                <img
                  class="h-96"
                  src="https://cdn.discordapp.com/attachments/1080058777971212370/1130741473307000852/blue-umbrella-2021-08-26-22-26-32-utc.jpg"
                />
                <div class="text-center">
                  <h3 class="text-xl pt-5">
                    Crystal Star Compact Collectible <br />
                    AirPods Case
                  </h3>
                </div>
              </div>

              <div class="">
                <img
                  class="h-96"
                  src="https://cdn.discordapp.com/attachments/1080058777971212370/1130728998415126658/Drinkware_04.png"
                />
                <div class="text-center">
                  <h3 class="text-xl pt-5">
                    Crystal Star Compact Collectible <br />
                    AirPods Case
                  </h3>
                </div>
              </div>

              <div>
                <img
                  class="h-96"
                  src="https://cdn.discordapp.com/attachments/1080058777971212370/1130728997853085817/Drinkware_02.png"
                />
                <div class="text-center">
                  <h3 class="text-xl pt-5">
                    Crystal Star Compact Collectible <br />
                    AirPods Case
                  </h3>
                </div>
              </div>
            </div>
            <hr class="mt-10 mb-10" />
          </div>

          <div class="mt-24">
            <img
              class="h-96 lg:h-full object-cover"
              src="https://cdn.discordapp.com/attachments/1080058777971212370/1126421049043595304/POD_Banner_Shop_Now.png"
            />

            <h3 class="text-5xl font-sans font-bold ml-12 -mt-16 text-gray-900">
              Snap & Go Collection
            </h3>

            <div
              class="inline-block mt-10 ml-12 p-px bg-gradient-to-r from-blue-700 to-red-500 rounded-2xl"
            >
              <div
                class="inline-block text-base px-2 py-1 sm:px-4 sm:py-2 border-none rounded-2xl text-gray-200 bg-gray-900 cursor-pointer hover:bg-gray-200 hover:text-gray-900"
              >
                Learn More
              </div>
            </div>
          </div>

          <hr class="mt-10 mb-10" />

          <div class="mt-44">
            <h3 class="text-3xl font-sans font-bold">Trending Designs!</h3>
            <div
              class="grid gap-4 grid-cols-1 lg:grid-cols-4 md:grid-cols-2 mt-10"
            >
              <div>
                <img
                  class="w-full"
                  src="https://cdn-image02.casetify.com/usr/14315/17384315/~v1/28644892_iphone-14-pro-max_16004759.png.560x560-w.jpg"
                />
                <div class="text-center">
                  <h3 class="text-xl pt-5">PLZ DO NOT DISTURB ME</h3>
                </div>
              </div>

              <div>
                <img
                  class="w-full"
                  src="https://cdn-stamplib.casetify.com/cms/image/7dc28554fefccff1667d13dcbd608f6d.png"
                />
                <div class="text-center">
                  <h3 class="text-xl pt-5">
                    Metal Heart Cross-body Strap - Glossy Silver
                  </h3>
                </div>
              </div>

              <div>
                <img
                  class="w-full"
                  src="https://cdn-image02.casetify.com/usr/21433/34611433/~v1/28862401_iphone-14-pro-max_16004815.png.560x560-w.jpg"
                />
                <div class="text-center">
                  <h3 class="text-xl pt-5">STOP LOOKING By Shuturp</h3>
                </div>
              </div>

              <div>
                <img
                  class="w-full"
                  src="https://cdn-stamplib.casetify.com/cms/image/53d1e8a43740be105fb1fd6ec78a4ea4.png"
                />
                <div class="text-center">
                  <h3 class="text-xl pt-5">
                    iPhone 14 Pro & 14 Pro Max Camera Lens <br />
                    Protector
                  </h3>
                </div>
              </div>
            </div>
            <hr class="mt-10 mb-10" />
          </div>

          <div class="mt-44">
            <h3 class="text-3xl font-sans font-bold">#CraftMastersCommunity</h3>
            <div
              class="grid gap-4 grid-cols-1 lg:grid-cols-3 md:grid-cols-2 mt-10"
            >
              <div class="text-center">
                <img
                  class="w-full"
                  src="https://cdn.discordapp.com/attachments/1080058777971212370/1126734786677968906/POD_Banner_Community_02.png"
                />
                <h3 class="text-lg font-bold pt-5">
                  LIFESTYLE INFLUENCER: @iva.carob X Frosted Sunset Case
                </h3>
              </div>

              <div class="text-center">
                <img
                  class="w-full"
                  src="https://cdn.discordapp.com/attachments/1080058777971212370/1126734766436253726/POD_Banner_Community_01.png"
                />
                <h3 class="text-lg font-bold pt-5">
                  FASHION MODEL: @xxdiyar X Be A Nice Human Case
                </h3>
              </div>

              <div class="text-center">
                <img
                  class="w-full"
                  src="https://cdn.discordapp.com/attachments/1080058777971212370/1126734821784289290/POD_Banner_Community_03.png"
                />
                <h3 class="text-lg font-bold pt-5">
                  ACTRESS: @sammy_3008 X Self Love Stickers Case
                </h3>
              </div>
            </div>
            <hr class="mt-16 mb-16" />
          </div>

          <div class="mt-40">
            <h3 class="text-3xl font-sans font-bold">CraftMasters Artist</h3>
            <div
              class="grid gap-4 grid-cols-1 lg:grid-cols-3 md:grid-cols-2 mt-10"
            >
              <div class="text-center">
                <img
                  class="w-full"
                  src="https://cdn.discordapp.com/attachments/1080058777971212370/1126462977365790832/POD_Banner_Artist_01.png"
                />
                <h3 class="text-lg font-bold pt-5">SSEBONG</h3>
              </div>

              <div class="text-center">
                <img
                  class="w-full"
                  src="https://cdn.discordapp.com/attachments/1080058777971212370/1126462977718104155/POD_Banner_Artist_02.png"
                />
                <h3 class="text-lg font-bold pt-5">YOUNG FOREST</h3>
              </div>

              <div class="text-center">
                <img
                  class="w-full"
                  src="https://cdn.discordapp.com/attachments/1080058777971212370/1126462978099793970/POD_Banner_Artist_03.png"
                />
                <h3 class="text-lg font-bold pt-5">Esther Bunny</h3>
              </div>
            </div>
            <hr class="mt-10 mb-16" />
          </div>

          <div class="mt-24">
            <h3 class="text-3xl font-sans font-bold">CraftMasters Artist</h3>

            <img
              class="w-full mt-5"
              src="https://cdn.discordapp.com/attachments/1080058777971212370/1126449730751299704/POD_Banner_Our_Mission.png"
            />
            <div class="text-center">
              <h3 class="text-lg font-bold pt-2">
                Re/CLAIM Re/IMAGINE Re/CraftMasters
              </h3>
              <h3 class="text-5xl font-sans font-bold pt-2 text-blue-900">
                Re/CraftMasters
              </h3>

              <div
                class="inline-block mt-5 p-px bg-gradient-to-r from-blue-700 to-red-500 rounded-2xl"
              >
                <div
                  class="inline-block text-base px-2 py-1 sm:px-4 sm:py-2 border-none rounded-2xl text-gray-200 bg-gray-900 cursor-pointer hover:bg-gray-200 hover:text-gray-900"
                >
                  Learn More
                </div>
              </div>
              <hr class="mt-16 mb-16" />
            </div>
          </div>

          <div class="mt-44">
            <h3 class="text-3xl font-sans font-bold">The Essentials</h3>
            <div
              class="grid gap-4 grid-cols-1 lg:grid-cols-4 md:grid-cols-2 mt-10"
            >
              <div>
                <img
                  class="w-full"
                  src="https://cdn-image02.casetify.com/usr/4117/23884117/22442651_custom-phone-strap_1240001.png.560x560-w.jpg"
                />
                <div class="text-center">
                  <h3 class="text-lg pt-5">Custom Phone Charm</h3>
                </div>
              </div>

              <div>
                <img
                  class="w-full"
                  src="https://cdn-stamplib.casetify.com/cms/image/53ebda6307737bff39f48b1d5ebab50d.png"
                />
                <div class="text-center">
                  <h3 class="text-lg pt-5">Rope Cross-body Strap - Black</h3>
                </div>
              </div>

              <div>
                <img
                  class="w-full"
                  src="https://cdn-stamplib.casetify.com/cms/image/6126155cc5b7a444a0e83e4dfd85c925.png"
                />
                <div class="text-center">
                  <h3 class="text-lg pt-5">
                    Anti Blue Light Tempered Glass with Anti Microbial function
                  </h3>
                </div>
              </div>

              <div>
                <img
                  class="w-full"
                  src="https://cdn-image02.casetify.com/usr/25496/1195496/26056367_laptop-sleeve-large_16004824.png.560x560-w.jpg"
                />
                <div class="text-center">
                  <h3 class="text-lg pt-5">boho hipple sticker</h3>
                </div>
              </div>
            </div>
            <hr class="mt-10 mb-10" />
          </div>

          <div class="mt-44">
            <h3 class="text-3xl font-sans font-bold">#CraftMastersCares</h3>
            <div
              class="grid gap-4 grid-cols-1 lg:grid-cols-3 md:grid-cols-2 mt-10"
            >
              <div>
                <img
                  class="w-full"
                  src="https://cdn-stamplib.casetify.com/cms/image/b9b0129a0aca61d2b92b5ddeef41f3e8.jpg.webp"
                />
                <div class="">
                  <h3 class="text-lg pt-5">It's Okay To Put Yourself First</h3>
                  <h3 class="text-md font-serif text-gray-700 pt-3">
                    During Mental Health Awareness Month, we're donating $5 from
                    every sale to To Write Love On Her Arms, a community
                    dedicated to preserving hope and finding help for people
                    struggling with depression, addiction, self-injury, and
                    suicide.
                  </h3>
                </div>
              </div>

              <div>
                <img
                  class="w-full"
                  src="https://cdn-stamplib.casetify.com/cms/image/e2c50ed35659ba1e35d9076ae96b5ff2.jpg.webp"
                />
                <div class="">
                  <h3 class="text-lg pt-5">Be Breast Aware</h3>
                  <h3 class="text-md font-serif text-gray-700 pt-3">
                    A collection to raise breast cancer awareness. CraftMasters
                    will donate US$5 for each case sold from the collection to
                    Living Beyond Breast Cancer during October.
                  </h3>
                </div>
              </div>

              <div>
                <img
                  class="w-full"
                  src="https://cdn-stamplib.casetify.com/cms/image/fbd2847eab3cfceab2cf2d774ffe5778.jpg.webp"
                />
                <div class="">
                  <h3 class="text-lg pt-5">Pawfect Love</h3>
                  <h3 class="text-md font-serif text-gray-700 pt-3">
                    Show your love for furry friends with this cuddly collection
                    that cares! During the month of August, CraftMasters will
                    donate $5 for every case sold to the IFAW.
                  </h3>
                </div>
              </div>
            </div>
            <hr class="mt-10 mb-10" />
          </div>
          <div class="mt-24">
            <h3 class="text-3xl font-sans font-bold">Join Us</h3>

            <img
              class="w-full mt-5"
              src="https://cdn.discordapp.com/attachments/1080058777971212370/1126454141745643571/POD_Banner_Join_Us.png"
            />
            <div class="text-center">
              <h3 class="text-sm font-bold pt-2">
                Get exclusive rewards and privileges
              </h3>
              <h3 class="text-5xl font-sans font-bold pt-2 text-blue-600">
                CraftMasters Club
              </h3>

              <div
                class="inline-block mt-5 p-px bg-gradient-to-r from-blue-700 to-red-500 rounded-2xl"
              >
                <div
                  class="inline-block text-base px-2 py-1 sm:px-4 sm:py-2 border-none rounded-2xl text-gray-200 bg-gray-900 cursor-pointer hover:bg-gray-200 hover:text-gray-900"
                >
                  Learn More
                </div>
              </div>
            </div>
          </div>
        </div>

        <StickFooter class="mt-44" />
      </div>
    </div>
  </div>
</template>

<script>
import ConnectWallet from "@/components/ConnectWallet.vue";
import StickFooter from "@/components/Footer.vue";

import { mapGetters, mapActions } from "vuex";

import { POOL_ADDRESS, VERSION } from "../../config";

import { Carousel, Navigation, Pagination, Slide } from "vue3-carousel";

// import { products, main_product, products_2 } from "../../products";

export default {
  data() {
    return {
      version: VERSION,
      products: [],
      products_2: [],

      ref: this.$route.params.ref,
      searchPrompt: null,
      slideList: [
        {
          img: "https://cdn.discordapp.com/attachments/1080058777971212370/1131111307366637629/Banner-Collectibles.jpg",
        },
        {
          img: "https://cdn.discordapp.com/attachments/1080058777971212370/1131111307697999872/Banner-OEM.jpg",
        },

        {
          img: "https://cdn.discordapp.com/attachments/1080058777971212370/1131111308025147412/Banner-TOP.jpg",
        },
        {
          img: "https://cdn.discordapp.com/attachments/1080058777971212370/1131111308398448750/Banner-Trending.jpg",
        },
      ],
    };
  },
  components: {
    ConnectWallet,
    StickFooter,
    Carousel,
    Navigation,
    Pagination,
    Slide,
  },
  computed: mapGetters(["getMenu"]),

  methods: {},
  created() {},
};
</script>
